var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-main",
        { staticClass: "pt-0", attrs: { fluid: "", tag: "section" } },
        [
          _c("TopBar", {
            ref: "topBar",
            attrs: { title: "Scheduled Forms" },
            on: { "show-notifications": _vm.onShowNotifications }
          }),
          _c(
            "v-navigation-drawer",
            {
              style: {
                "z-index": 10,
                "background-color": "#fafafa"
              },
              attrs: {
                app: !_vm.$vuetify.breakpoint.xsOnly,
                right: "",
                permanent: _vm.showNotificationPane,
                "hide-overlay": "",
                width: "375px",
                stateless: "",
                absolute: _vm.$vuetify.breakpoint.xsOnly
              },
              model: {
                value: _vm.showNotificationPane,
                callback: function($$v) {
                  _vm.showNotificationPane = $$v
                },
                expression: "showNotificationPane"
              }
            },
            [
              _vm.showNotificationPane
                ? _c("NotificationsPane", {
                    on: {
                      "close-notification-pane": function($event) {
                        _vm.showNotificationPane = false
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "600px",
                persistent: "",
                fullscreen: _vm.isFullScreen
              },
              model: {
                value: _vm.showEditFormDialog,
                callback: function($$v) {
                  _vm.showEditFormDialog = $$v
                },
                expression: "showEditFormDialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _vm.showEditFormDialog
                    ? _c("DynamicForm", {
                        attrs: {
                          formDefinition: _vm.dynamicFormProps.formDefinition,
                          existingFormResultIdMap:
                            _vm.dynamicFormProps.existingFormResultIdMap,
                          selectedPdfFileUrl:
                            _vm.dynamicFormProps.selectedPdfFileUrl,
                          canEdit: _vm.dynamicFormProps.canEdit,
                          alreadySubmittedFinalOnline:
                            _vm.dynamicFormProps.alreadySubmittedFinalOnline,
                          globalId: _vm.dynamicFormProps.globalId,
                          objectId: _vm.dynamicFormProps.objectId,
                          selectedMapServiceId:
                            _vm.dynamicFormProps.selectedMapServiceId,
                          taskId: _vm.dynamicFormProps.taskId
                        },
                        on: {
                          "ticket-edit-form-close-button-click": function(
                            $event
                          ) {
                            _vm.showEditFormDialog = false
                          },
                          "ticket-edit-form-close": function($event) {
                            _vm.showEditFormDialog = false
                          },
                          "ticket-edit-form-submitted": function($event) {
                            _vm.showEditFormDialog = false
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm.showAuthExpiredDialog
            ? _c("AuthExpiredDialog", {
                attrs: { showAuthExpiredDialog: _vm.showAuthExpiredDialog },
                on: {
                  "portal-login-success": function($event) {
                    _vm.showAuthExpiredDialog = false
                  },
                  "auth-expired-dialog-close": function($event) {
                    _vm.showAuthExpiredDialog = false
                  }
                }
              })
            : _vm._e(),
          _c("MapViewTasksView", {
            staticClass: "py-5",
            attrs: { topBarHeight: _vm.topBarHeight }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }